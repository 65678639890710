import React, { useState, useContext, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import { CheckoutContext } from "~/providers/checkoutProvider";
import PaymentSelector from "./paymentSelector";
import CheckoutStripeForm from "./stripe/checkout-stripe-form";
import CheckoutPaypalForm from "./paypal/checkout-paypal-form";
import CheckoutFreeForm from "./free/checkout-free-form";
import { useForm } from "~/hooks/useForm";

const PaymentForms = ({ stripe, auth, stripePaymentMethods }) => {
  const [logged, setAuthStatus] = useState(null);
  const [state, setState] = useContext(CheckoutContext);
  const { country, total } = state;

  const [values, handleChange] = useForm({
    aceptPromo: true,
    paymentMethod: "credit-card",
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      promo: Boolean(values.aceptPromo),
    }));

    if (auth.email === null) setAuthStatus(false);
    else setAuthStatus(true);

    return () => {
      return false;
    };
  }, [values, auth]);

  if (country === null || !stripe) {
    return null;
  }

  const eur = [
    "DE",
    "AT",
    "BE",
    "CY",
    "SK",
    "SI",
    "ES",
    "EE",
    "FI",
    "FR",
    "GR",
    "IE",
    "IT",
    "LT",
    "LV",
    "LU",
    "MT",
    "NL",
    "PT",
    "AD",
    "MC",
    "SM",
    "VA",
    "ME",
    "XK",
  ];

  // console.log({total} );

  return (
    <div className="h-full flex items-center">
      <div className="w-full">
        {!total.subForeverFree || total.total > 0 ? (
          <>
            <div className="text-center">
              <h3 className="">
                <Trans>Select payment method</Trans>
              </h3>
            </div>
            <div className="text-center text-brand-primary mb-6 font-bold">
              <Trans>30-Days Money-back Guarantee</Trans>
            </div>
            {total.total == 0 && (
              <div className="bg-green-100 border border-green-200 p-3 rounded-brand text-sm">
                <ul>
                  <li className="font-semibold">
                    <span className="icon icon-check1 text-green-600 font-extrabold" />{" "}
                    <Trans>You will pay 0 {total.symbol} today</Trans>
                  </li>
                  <li>
                    <span className="icon icon-check1 text-green-600 font-extrabold" />{" "}
                    <Trans>You can cancel before the first charge</Trans>
                  </li>
                  <li>
                    <span className="icon icon-check1 text-green-600 font-extrabold" />{" "}
                    <Trans>
                      The first charge will be after the free period
                    </Trans>
                  </li>
                </ul>
              </div>
            )}
            <div className="flex mt-2 mb-2 ">
              <PaymentSelector
                handlePaymentMethod={handleChange}
                paymentMethod={values.paymentMethod}
              />
            </div>
            <div
              className={
                values.paymentMethod === "credit-card" ? "block" : "hidden"
              }
            >
              <CheckoutStripeForm
                stripe={stripe}
                stripePaymentMethods={stripePaymentMethods}
              />
            </div>
            {values.paymentMethod === "paypal" && <CheckoutPaypalForm />}
          </>
        ) : (
          <CheckoutFreeForm />
        )}
        <div className="mt-4 text-gray-600 text-sm text-center">
          <Trans>
            By completing this purchase, you agree to our &nbsp;
            <a
              href="/terms"
              target="_blank"
              className="no-underline text-gray-700 hover:text-gray-700"
            >
              Terms and Conditions
            </a>
          </Trans>
        </div>
        {!logged && eur.includes(country) && (
          <div className="mt-4 text-gray-600 text-sm text-center flex items-center space-x-2 justify-center">
            <label className="checkbox mt-1">
              <input
                type="checkbox"
                checked={values.aceptPromo}
                name="aceptPromo"
                onChange={handleChange}
                className="mr-2 align-middle"
              />
              <i className="custom-checkbox" />
            </label>
            <Trans>
              I would like to receive free courses offers, promotions and course
              update emails.
            </Trans>
          </div>
        )}
        <div className="flex justify-center items-center space-x-2 my-6">
          <img
            src="https://res.cloudinary.com/ie1/image/upload/f_auto,w_141,q_auto/v1594720493/vtutor/nor_sec_jkq5by.png"
            alt="nor secure"
            className="hidden"
            style={{ width: 94 }}
          />
          <img
            src="/images/30-day-guarantee-vtutor.svg"
            alt="garanty"
            style={{ width: 40 }}
          />
          <img
            src="/images/secure-purchase-vtutor.svg"
            alt="secure pruchase"
            style={{ width: 84 }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(PaymentForms);
