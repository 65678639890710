import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import "~/css/wizard.scss";

const Steps = ({ step, data, defaultSection }) => {
  const [selector, setSelector] = useState({
    upsell: true,
    password: false,
    finish: false,
  });

  useEffect(() => {
    switch (step) {
      case "upsell":
        setSelector({ ...selector, upsell: true });
        break;
      case "password":
        setSelector({ ...selector, upsell: true, password: true });
        break;
      case "finish":
        setSelector({ upsell: true, password: true, finish: true });
        break;
      default:
        setSelector({ ...selector, upsell: true });
        break;
    }

    return () => {
      return false;
    };

  }, [step]);

  return (
    <ul className="progress-indicator">
      {data.upsell.length > 0 && (
        <li className={`font-semibold ${selector.upsell && "completed"}`}>
          <span className="bubble"></span> <Trans>SELECT AN OFFER</Trans>
        </li>
      )}

      {data.guest && (
        <li
          className={`font-semibold ${
            (selector.password || (selector.password && defaultSection)) &&
            "completed"
          }`}
        >
          <span className="bubble"></span> <Trans>CREATE PASSWORD</Trans>
        </li>
      )}

      <li className={`font-semibold ${selector.finish && "completed"}`}>
        <span className="bubble"></span> <Trans>FINISH</Trans>
      </li>
    </ul>
  );
};

export default Steps;
