import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import Steps from "./steps";
import Upsell from "./upsell";
import Password from "./password";
import Finish from "./finish";
import loader from "~/images/ball-triangle.svg";
import useWizardUpsells from "~/hooks/useWizardUpsells";

const Wizard = (props) => {
  const [section, setSection] = useState(props.section);

  const { data, isLoading, error } = useWizardUpsells();

  let defaultSection = false;

  useEffect(() => {
    return () => {
      return false;
    };
  }, [section]);

  const renderSection = () => {
    switch (section) {
      case "upsell":
        return (
          <Elements stripe={props.stripe}>
            <Upsell changeSection={setSection} data={data} />
          </Elements>
        );
      case "password":
        return <Password changeSection={setSection} token={data.token} />;
      case "finish":
        return <Finish changeSection={setSection} />;
      default:
        defaultSection = true;
        if (data && data.upsell.length > 0) {
          return (
            <Elements stripe={props.stripe}>
              <Upsell changeSection={setSection} data={data} />
            </Elements>
          );
        } else if (data && data.guest) {
          return <Password changeSection={setSection} token={data.token} />;
        } else {
          return <Finish changeSection={setSection} />;
        }
    }
  };

  return (
    <div className="flex flex-col md:flex-row md:mt-4 md:space-x-8">
      <div className="flex-1 shadow px-4 py-2 md:p-4 md:pt-8 rounded-brand border border-gray-300 flex flex-col" style={{ minHeight: "30rem" }}>
        {section !== "finish" && (
          <div className="font-semibold text-center mb-3">
            <Trans>Almost finished</Trans>
          </div>
        )}
        {isLoading ? (
          <div className="flex-1 flex items-center justify-center" style={{ minWidth: 250 }}>
            <img src={loader} alt="please wait..." className="w-32 h-32" />
          </div>
        ) : (
          <>
            <div className="">
              {section !== "finish" && ((data && data.upsell.length > 0) || data.guest) && (
                <Steps step={section} data={data} defaultSection={defaultSection} />
              )}
            </div>
            {renderSection()}
          </>
        )}
      </div>
    </div>
  );
};

Wizard.defaultProps = {
  section: null,
  loading: true,
};

Wizard.propTypes = {
  section: PropTypes.string,
};

export default Wizard;
