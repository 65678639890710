import React, { useState, useContext, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { useForm } from "react-hook-form";
import validator from "validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addCourse } from "~/redux/actions/auth_actions";
import Input from "~/components/input";
import InputError from "~/components/inputError";
import { CheckoutContext } from "~/providers/checkoutProvider";
import { capturePayment } from "~/services/free/capturePayment";
import { sendOrder } from "~/services/gtm/sendOrder";
import Notification from "~/components/notification";
import LoadingOrder from "../loadingOrder";

const CheckoutFreeForm = ({ auth, i18n, addCourse }) => {
  const [logged, setAuthStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const [state, setState] = useContext(CheckoutContext);
  const {
    total,
    courses,
    coupon,
    success,
    ga,
    lang,
    promo,
    gtm,
    utm_src
  } = state;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (body) => {
    try {
      setLoading(true);
      setGlobalError("");
      const courseData = {
        amount: Math.round(total.total * 100),
        courses,
        coupon,
        name: logged ? auth.full_name : " ",
        email: logged ? auth.email : body.email,
        ga: ga,
        utm_src: utm_src,
        lang: lang,
        promo: promo
      };

      const { error, success, data } = await capturePayment(courseData);

      if (error) {
        throw new Error(error.message);
      } else {
        if (success) {
          //update auth state and add the new courses
          addCourse(courses.split(","));

          //send data to GTM
          let order = {
            transaction_id: data.orderID,
            total: total.total,
            currency: total.coin,
            coupon: coupon,
            items: gtm.items
          };

          sendOrder(order);
          // ---------------

          setState((state) => ({
            ...state,
            success: true,
            orders: [data.orderID],
            paymentMethod: {
              method: "free",
              payment_id: ""
            },
            name: logged ? auth.full_name : " ",
            email: logged ? auth.email : body.email
          }));
        } else {
          throw new Error(
            (
              <Trans>
                Something happened, please refresh the window and try again.
              </Trans>
            )
          );
        }
      }
    } catch (e) {
      Bugsnag.notify(e);
      setGlobalError(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.email === null) setAuthStatus(false);
    else setAuthStatus(true);

    return () => {
      return false;
    };
  }, [auth]);

  return (
    <>
      {loading && <LoadingOrder success={success} />}
      {globalError && (
        <Notification type="error" className="text-center">
          {globalError}
        </Notification>
      )}
      <form>
        {logged === false && (
          <div className="mt-4 md:mt-5 relative mb-3">
            <label
              htmlFor="email"
              className={`absolute -mt-3 ml-4 bg-white px-1 ${
                typeof errors["email"] !== "undefined" && "text-red-700"
              }`}
            >
              <Trans>Email</Trans>
            </label>
            <Input
              type="email"
              {...register("email", {
                required: { value: true, message: i18n._(t`Required field`) },
                validate: (value) =>
                  validator.isEmail(value) || i18n._(t`Invalid e-mail address`)
              })}
              id="email"
              error={typeof errors["email"] !== "undefined"}
              placeholder="Email to send the access to the course"
              className="h-12"
              dynamicPlaceholder={false}
              autoComplete="off"
            />
            <InputError errors={errors} field="email" />
          </div>
        )}
        <button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          className="btn btn-primary w-full block text-center"
        >
          <span className="text-xl">
            <Trans>Enroll now</Trans>
          </span>
        </button>
      </form>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCourse: addCourse
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withI18n()(CheckoutFreeForm));
