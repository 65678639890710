import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import Bugsnag from "@bugsnag/js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cleanCartAction } from "~/redux/actions/cart_actions";
import { CheckoutContext } from "~/providers/checkoutProvider";
import { getPaymentMethods } from "~/services/stripe/getPaymentMethods";
import Wizard from "~/components/checkout/wizard/wizard";
import OrderSummary from "./orderSummary";
import LoaderScreenMain from "./loaderScreenMain";
import PaymentForms from "./paymentForms";
import { isClient } from "~/utils/app-helpers";

const stripe = isClient
  ? loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
  : null;

const Payment = ({ cart, auth, cleanCart }) => {
  const [{ success, paymentMethod }] = useContext(CheckoutContext);
  const [goWizard, setGoWizard] = useState(false);
  const [stripePaymentMethods, setStripePaymentMethods] = useState([]);
  const [loading, setLoadingState] = useState("loading");
  const { email } = auth;

  const getAndSetPaymentMethods = async () => {
    try {
      const { error, payment_methods } = await getPaymentMethods();

      if (error) {
        throw new Error(error);
      } else {
        if (
          typeof payment_methods !== "undefined" &&
          payment_methods.length > 0
        ) {
          setStripePaymentMethods(payment_methods);
        }

        setLoadingState("finished");
      }
    } catch (e) {
      Bugsnag.notify(e);
      setLoadingState("error");
    }
  };

  useEffect(() => {
    if (cart === null) return false;

    if (email !== null) {
      getAndSetPaymentMethods();
    } else {
      setLoadingState("finished");
    }

    if (success) {
      if (isClient) {
        sessionStorage.removeItem("vtCart");
        cleanCart();

        if (paymentMethod.method === "paypal") setGoWizard(true);
        else setTimeout(() => setGoWizard(true), 2000);
      }
    }

    return () => {
      return false;
    };
  }, [success, email, paymentMethod, cleanCart, cart]);

  return (
    <>
      {goWizard ? (
        <Wizard stripe={stripe} />
      ) : (
        <div className="flex flex-col md:flex-row md:mt-4 md:space-x-8">
          <div
            className="flex-1 shadow px-4 py-2 md:p-4 rounded-brand border border-gray-300 relative"
            style={{ minHeight: 150 }}
          >
            <LoaderScreenMain
              loading={Boolean(email && loading === "loading")}
              stripe={stripe}
            />
            <PaymentForms
              stripe={stripe}
              stripePaymentMethods={stripePaymentMethods}
            />
          </div>
          <div className="md:self-start shadow p-4 rounded-brand mt-4 md:mt-0 md:w-1/3 border border-gray-300">
            <OrderSummary cart={cart} />
          </div>
        </div>
      )}
    </>
  );
};

Payment.propTypes = {
  cart: PropTypes.object.isRequired,
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      cleanCart: cleanCartAction,
    },
    dispatch
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Payment);
