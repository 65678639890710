import React, { useContext } from "react";
import { Trans } from "@lingui/macro";
import { CheckoutContext } from "~/providers/checkoutProvider";

const LoaderScreenMain = ({ loading, stripe }) => {
  const [state] = useContext(CheckoutContext);
  const { country } = state;

  if (country !== null && loading === false && stripe !== null) {
    return null;
  }

  return (
    <div className="h-full w-full bg-white z-20 bg-opacity-75 flex items-center justify-center absolute top-0 left-0 rounded-brand">
      <div>
        <div className="text-center">
          <span className="icon icon-lock-alt text-4xl" />
        </div>
        <Trans>Loading order... please wait...</Trans>
      </div>
    </div>
  );
};

export default LoaderScreenMain;
