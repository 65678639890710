import React from "react";
import { Trans } from "@lingui/macro";

const LoadingCheckout = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="mt-52 text-2xl md:text-3xl md:mt-64">
        <Trans>Loading... please wait...</Trans>
        <div className="loading-bar" />
      </div>
    </div>
  );
};

export default LoadingCheckout;
