import React, { useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import CoursesList from "./coursesList";
import usePrices from "~/hooks/usePrices";
import useOrderSummary from "~/hooks/useOrderSummary";
import loading from "~/images/loading.svg";
import PricesContext from "~/providers/pricesProvider";
import { CheckoutContext } from "~/providers/checkoutProvider";
import { isClient, getCookie } from "~/utils/app-helpers";
import CouponBox from "~/components/couponBox";
import {
  getListIdsFromCart,
  getCoursesNamesFromCart,
  getItemsforGTMfromCart,
} from "~/utils/order-summary-helpers";

const OrderSummary = ({ cart, i18n }) => {
  const { language } = i18n;
  const [, setState] = useContext(CheckoutContext);

  //  --- ACA es para los precios ---
  const joinedIds = getListIdsFromCart(cart);
  // --------------------------------

  const { data: myPrices } = usePrices(joinedIds);

  const {
    loaded,
    prices,
    total,
    subtotal,
    discount,
    mixed,
    multiSubs,
    couponValid,
  } = useOrderSummary(myPrices);

  const updateCheckoutContext = useCallback(() => {
    // items build for GTM
    const items = getItemsforGTMfromCart(cart, myPrices);
    // -------------------

    const coursesData = getCoursesNamesFromCart(cart);

    setState((s) => ({
      ...s,
      total: total,
      courses: joinedIds,
      coursesData: coursesData,
      country: myPrices.country,
      mixed: mixed,
      coupon:
        isClient && couponValid ? sessionStorage.getItem("vtCoupon") : null,
      lang: language,
      multiSubs: multiSubs,
      ga: getCookie("_ga"),
      gtm: { items },
      utm_src: getCookie("c_utm_sm"),
    }));
  }, [
    cart,
    myPrices,
    language,
    joinedIds,
    mixed,
    multiSubs,
    total,
    couponValid,
    setState,
  ]);

  useEffect(() => {
    if (loaded) {
      updateCheckoutContext();
    }

    return () => {
      return false;
    };
  }, [loaded, updateCheckoutContext]);

  return (
    <div>
      <h3 className="text-center">
        <Trans>Order summary</Trans>
      </h3>
      {loaded && discount !== 0 && (
        <div>
          <div className="flex items-center justify-between mt-1 md:mt-4">
            <span>
              <Trans>Original price</Trans>:
            </span>
            <span
              className={`text-sm md:text-base ${
                !isNaN(subtotal) && "line-through"
              }`}
            >
              {subtotal}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span>
              <Trans>Discount</Trans>:
            </span>
            <span className="text-red-400 text-sm md:text-base">
              {discount}
            </span>
          </div>
        </div>
      )}

      <div className="flex items-center justify-between mt-2 pt-2 border-t border-gray-200">
        <span className="font-medium text-2xl">Total:</span>
        <span className="text-xl md:text-3xl font-bold text-brand-primary align-middle">
          {loaded ? (
            <>
              {} {total.total} {total.symbol}
            </>
          ) : (
            <img src={loading} alt={i18n._(t`Loading`)} />
          )}
        </span>
      </div>
      <CouponBox disable={prices === ""} />
      <div className="mt-6">
        <PricesContext.Provider value={prices}>
          <CoursesList cart={cart} />
        </PricesContext.Provider>
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  i18n: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
};

export default withI18n()(OrderSummary);
