export function getListIdsFromCart(cart) {
  if (Object.keys(cart).length > 0) {
    const ids = cart.products.map((course) => course.ID);

    return ids.join(",");
  }

  return "";
}

export function getCoursesNamesFromCart(cart) {
  if (Object.keys(cart).length > 0) {
    const coursesData = cart.products.reduce((acum, course) => {
      return {
        ...acum,
        [course.ID]: course.title,
      };
    }, {});

    return coursesData;
  }

  return {};
}

export function getItemsforGTMfromCart(cart, myPrices) {
  if (Object.keys(cart).length > 0) {
    return cart.products.map((course) => {
      return {
        item_name: course.title,
        item_id: course.ID,
        price: getItemPrice(myPrices[course.ID]),
        quantity: 1,
      };
    });
  }

  return [];
}

function getItemPrice(course_details) {
  if (typeof course_details.sub_details !== "undefined" && course_details.sub_details) {
    if (course_details.sub_details.period_price !== "free") {
      return course_details.sub_details.period_price;
    }

    return "0";
  }

  return course_details.price;
}
