export async function captcha(captcha_token) {
  let error = null;
  let result = "fail";

  try {
    const response = await fetch(`${process.env.GATSBY_AUTH_URL}/captcha/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: captcha_token,
      }),
    });

    const { error } = await response.json();

    if (!response.ok) {
      throw new Error(error ? error : response.statusText);
    }

    if (error) {
      throw new Error(error);
    } else {
      result = "success";
    }
  } catch (e) {
    error = e;
  }

  return { error, result };
}
