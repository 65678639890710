import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { useForm } from "react-hook-form";
import { CheckoutContext } from "~/providers/checkoutProvider";
import { resetPassword } from "~/services/auth/resetPass";
import Input from "~/components/input";
import InputError from "~/components/inputError";
import ButtonStateful from "~/components/buttonStateful";
import { newPasswordValidator } from "~/components/passwordValidator";
import Notification from "~/components/notification";

const Password = ({ i18n, changeSection, disableForm, token }) => {
  const [{ email, lang }] = useContext(CheckoutContext);
  const {
    register,
    handleSubmit,
    getValues,

    formState: {
      errors,
    },
  } = useForm({
    mode: "onBlur",
  });

  const [errorMsg, setError] = useState(null);
  const [isDisable, setDisableForm] = useState(disableForm);

  const onSubmit = async (body) => {
    setError(null);
    setDisableForm(true);

    try {
      const { error, success, data } = await resetPassword(email, token, body.password, lang);

      if (error) {
        throw new Error(error.message);
      }

      if (success) {
        changeSection("finish");
        return false;
      } else {
        let msg = "";

        switch (data.code) {
          case 1:
            msg = <Trans>Looks like the token session is not found, please contact support</Trans>;
            break;
          case 2:
            msg = <Trans>Looks like the token session is invalid, please contact support</Trans>;
            break;
          case 3:
            msg = <Trans>Looks like the session is expired, please contact support</Trans>;
            break;
          default:
            Bugsnag.notify(new Error('Reset error: ' + JSON.stringify(data)));
            msg = <Trans>Uknown error, please contact support</Trans>;
        }

        setError(msg);
      }
    } catch (e) {
      Bugsnag.notify(e);
      setError(e.message);
    }

    setDisableForm(false);
  };

  return <>
    <div className="text-center mt-4 h-full">
      <h2 className="mt-4">
        <Trans>Set up a password for your new account</Trans>
      </h2>
      <div className="sm:p-4 md:w-2/3 xl:w-1/2 inline-block">
        <div className="text-sm my-3 mb-8">
          <span className="text-lg">
            <Trans>In order to protect your account ensure your new password:</Trans>
          </span>
          <ul className="list-disc text-left ml-4 sm:ml-12 mt-4 text-base">
            <li>
              <Trans>Is longer than 7 characters</Trans>
            </li>
            <li>
              <Trans>has at least 1 number</Trans>
            </li>
            <li>
              <Trans>has at least 1 uppercase letter</Trans>
            </li>
            <li>
              <Trans>has at least 1 lowercase letter</Trans>
            </li>
          </ul>
        </div>
        <div className="mb-2">
          <Trans>Your account email</Trans>: <span className="font-semibold">{email}</span>
        </div>
        {errorMsg && (
          <Notification type="error" className="text-center">
            {errorMsg}
          </Notification>
        )}
        <form>
          <fieldset disabled={isDisable}>
            <div className="mb-3 md:mb-4">
              <Input
                type="showHide"
                placeholder={i18n._(t`Password`)}
                {...register('password', {
                  required: true,
                  minLength: {
                    value: 8,
                    message: i18n._(t`The min value is 8`),
                  },
                  validate: newPasswordValidator,
                })}
                required
                autoComplete="new-password"
                error={typeof errors["password"] !== "undefined"} />
              <InputError errors={errors} field="password" />
            </div>
            <div className="mb-3 md:mb-4">
              <Input
                type="showHide"
                placeholder={i18n._(t`Confirm Password`)}
                {...register('confirmPassword', {
                  validate: (value) => value === getValues().password || i18n._(t`Passwords do not match`),
                })}
                required
                autoComplete="new-password"
                error={typeof errors["confirmPassword"] !== "undefined"} />
              <InputError errors={errors} field="confirmPassword" />
            </div>
          </fieldset>
          <ButtonStateful type="submit" onClick={handleSubmit(onSubmit)} loading={isDisable} className="uppercase mt-6">
            <Trans>Save Password</Trans>
          </ButtonStateful>
        </form>
      </div>
    </div>
  </>;
};

Password.defaultProps = {
  disableForm: false,
};

Password.propTypes = {
  changeSection: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default withI18n()(Password);
