import React, { useContext, useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import { Image } from "~/components/auth/submitSuccess";
import { CheckoutContext } from "~/providers/checkoutProvider";
import Link from "~/components/link";
import { wpURL } from "~/utils/app-helpers";
import { useLang } from "~/../langProvider.js";

const Finish = (props) => {
  const [{ email }] = useContext(CheckoutContext);
  const [logged, setLoginStatus] = useState(null);
  const lang = useLang();

  useEffect(() => {
    if (props.auth.email) setLoginStatus(true);
    else setLoginStatus(false);

    return () => {
      return false;
    };
  }, [props.auth.email]);

  return (
    <>
      <div className="text-center mt-4">
        <div className="inline-block w-32">
          <Image />
        </div>
        <h2 className="mt-4">
          <Trans>Thank you for your purchase.</Trans>
        </h2>
        <div className="mt-1">
          <Trans>An email has been sent with your purchase details to</Trans>{" "}
          <span className="font-semibold">{email}</span>
        </div>
        {/* <div className="mt-8 text-lg">
          <p>
            <Trans>You're all set up to start learning</Trans>
          </p>
          <p>
            <Trans>
              Visit&nbsp;
              {logged !== null && logged ? (
                <a href={`${wpURL(lang)}/learner/my-purchased-courses/`} className="text-brand-primary font-semibold hover:underline">
                  My courses&nbsp;
                </a>
              ) : (
                <Link to="/login" className="text-brand-primary font-semibold hover:underline">
                  My courses&nbsp;
                </Link>
              )}
              in your account on <span className="font-semibold">vtutor.com</span> and start learning.
            </Trans>
          </p>
        </div> */}
        <div className="text-center mt-6 mb-4">
          {logged !== null && logged ? (
            <a
              href={`${wpURL(lang)}/learner/my-purchased-courses/`}
              className="btn btn-primary whitespace-nowrap uppercase w-48"
            >
              <span>
                <Trans>Go to my courses</Trans>
              </span>
            </a>
          ) : (
            <Link
              to="/login"
              className="btn btn-primary whitespace-nowrap uppercase w-48"
            >
              <span>
                <Trans>Go to my courses</Trans>
              </span>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(Finish);
