import React, { useState, useEffect } from "react";
import Product from "./productSmall";
import ProductUpsell from "./productUpsell";

const CoursesList = (props) => {
  const [cart, setCart] = useState({});
  let carChangeCheck = JSON.stringify(props.cart);
  const isUpsell = props.upsell;

  useEffect(() => {
    setCart(JSON.parse(carChangeCheck));

    return () => {
      return false;
    };

  }, [carChangeCheck]);

  return (
    <div>
      {Object.keys(cart).length > 0 ? (
        <ul className="space-y-3">
          {cart.products.map((product, index) => {
            const funcCart = (product) => (e) => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            };

            return (
              <li key={index + "_cart_item"}>
                {isUpsell ? (
                  <ProductUpsell {...product} />
                ) : (
                  <Product
                    {...product}
                    {...props}
                    funcCart={funcCart}
                    showRemove={false}
                  />
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <> </>
      )}
    </div>
  );
};

CoursesList.defaultProps = {
  upsell: false,
};

export default CoursesList;
