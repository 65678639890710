import { isClient } from "~/utils/app-helpers";

export function sendOrder(order) {
  if (isClient) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          purchase: {
            transaction_id: order.transaction_id,
            value: order.total,
            currency: order.currency,
            coupon: order.coupon === null ? " " : order.coupon,
            items: order.items,
          },
        },
      });
    }
  }
}
