export async function captureSubscriptionOrder(ID, email) {
  let error = null;
  let result = null;

  try {
    const response = await fetch(
      `${process.env.GATSBY_API_CHECKOUT}/paypal/subscription/${ID}/capture/`,
      {
        method: "post",
        body: JSON.stringify({
          email,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    result = await response.json();
  } catch (e) {
    error = e;
  }

  return { error, result };
}
