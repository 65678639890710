import { isClient } from "~/utils/app-helpers";

export function sendOrder(email, order) {
  const type = order.total > 0 ? "customer" : "trial";

  if (isClient) {
    if (window.tap) {
      window.tap("create", process.env.GATSBY_TAPFILIATE_ACCOUN_ID);
      window.tap(type, email);

      if (order.total > 0)
        window.tap("conversion", order.transaction_id, order.total, { currency: order.currency, meta_data: { currency: order.currency } });
    }
  }
}
