import React from "react";
import ReactDOM from "react-dom";

class PayPalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSdkReady: false,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined" && window.paypal === undefined && this.state.isSdkReady === false) {
      this.addPaypalSdk();
    } else if (typeof window !== "undefined" && window.paypal !== undefined && this.props.onButtonReady) {
      this.props.onButtonReady();
    }
  }

  render() {
    const { createOrder, createSubscription, onApprove, style, onInit, onClick } = this.props;
    const { isSdkReady } = this.state;

    if (!isSdkReady && (typeof window === "undefined" || window.paypal === undefined)) {
      return null;
    }

    const Button = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });

    return (
      <Button
        {...this.props}
        onInit={(data, actions) => onInit(data, actions)}
        onClick={onClick}
        createOrder={createSubscription ? undefined : createOrder}
        createSubscription={createSubscription}
        onApprove={(data, actions) => onApprove(data, actions)}
        style={style}
      />
    );
  }

  addPaypalSdk() {
    const { options, onButtonReady } = this.props;
    const queryParams = [];

    // replacing camelCase with dashes
    Object.keys(options).forEach((k) => {
      const name = k
        .split(/(?=[A-Z])/)
        .join("-")
        .toLowerCase();
      queryParams.push(`${name}=${options[k]}`);
    });

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = `https://www.paypal.com/sdk/js?${queryParams.join("&")}`;
    script.async = true;
    script.onload = () => {
      this.setState({ isSdkReady: true });

      if (onButtonReady) {
        onButtonReady();
      }
    };
    script.onerror = () => {
      throw new Error("Paypal SDK could not be loaded.");
    };

    document.body.appendChild(script);
  }
}

PayPalButton.defaultProps = {
  style: {},
  options: {
    clientId: "sb",
    currency: "USD",
  },
  shippingPreference: "NO_SHIPPING",
  onInit: (params) => {
    return null;
  },
};

export default PayPalButton;
