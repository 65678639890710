export async function createSubscription(body) {
  let error = null;
  let result = null;

  try {
    const response = await fetch(
      `${process.env.GATSBY_API_CHECKOUT}/paypal/billing/plan  `,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    result = await response.json();
  } catch (e) {
    error = e;
  }

  return { error, result };
}
