import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { bindActionCreators } from "redux";
import { addCourse } from "~/redux/actions/auth_actions";
import Bugsnag from "@bugsnag/js";
import { Trans } from "@lingui/macro";
import PayPalButton from "./checkout-paypal-button";
import Input from "~/components/input";
import InputValidationMessage from "~/components/inputValidationMessage";
import LoaderPaypal from "./loaderPaypal";
import { useForm } from "~/hooks/useForm";
import { CheckoutContext } from "~/providers/checkoutProvider";
import { createOrder } from "~/services/paypal/createOrder";
import { createSubscription } from "~/services/paypal/createSubscription";
import { captureOneTimeOrder } from "~/services/paypal/captureOneTimeOrder";
import { captureSubscriptionOrder } from "~/services/paypal/captureSubscriptionOrder";
import { sendOrder } from "~/services/gtm/sendOrder";
import { sendOrder as tapfiliateSendOrder } from "~/services/tapfiliate/sendOrder";
import ButtonStateful from "~/components/buttonStateful";
import { isClient } from "~/utils/app-helpers";

const Form = ({ auth, addCourse }) => {
  const [state, setState] = useContext(CheckoutContext);
  const { total, courses, coursesData, coupon, ga, lang, promo, gtm, utm_src } = state;

  const [visible, setVisibility] = useState(false);
  const [errors, setErrors] = useState({});
  const [globalError, setGlobalError] = useState("");
  const [values, handleChange] = useForm({
    emailPayPal: "",
  });
  const [orderVtutor, setOrderVtutor] = useState(null);

  const [logged, setLoginStatus] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (auth.email === null) setLoginStatus(false);
    else setLoginStatus(true);

    return () => {
      return false;
    };
  }, [auth]);

  const loading = () => {
    return !visible || !Boolean(total) || total.total === null;
  };

  const handleInit = (data, actions) => {
    // actions.disable();
    // actions.disable();
    // // setAllowActions(actions);
    // if (values.email !== "") actions.enable();
    // else {
    //   actions.disable();
    // }
  };

  const handleValidation = (data, actions) => {
    if (!logged) {
      let myErrors = {};
      setGlobalError("");

      Object.keys(values).forEach((i) => {
        if (values[i] === "") {
          myErrors[i] = "Required field";
        } else if (!isEmail(values[i])) {
          myErrors[i] = "Invalid e-mail address";
        }
      });

      if (Object.keys(myErrors).length > 0) {
        setErrors(myErrors);
        return actions.reject();
      } else {
        setErrors({});
        return actions.resolve();
      }
    }
  };

  const handleCreateOrder = async () => {
    setProcessing(true);

    const body = {
      amount: Math.round(total.total * 100),
      courses,
      coursesData,
      coupon: coupon,
      email: logged ? auth.email : values.emailPayPal,
      name: logged ? auth.full_name : " ",
      ga: ga,
      utm_src: utm_src,
      lang: lang,
      promo: promo,
    };

    try {
      const { error, result } = await createOrder(body);
      if (error) {
        throw new Error(error.message);
      } else {
        setOrderVtutor(result.orderVtutor);
        return result.orderID;
      }
    } catch (error) {
      Bugsnag.notify(error);
      setGlobalError(<Trans>Connection error, please refresh your page</Trans>);
    }
  };

  const handleOnApprove = async (data, actions) => {
    try {
      const email = logged ? auth.email : values.emailPayPal;

      if (data.subscriptionID) {
        //es una subscription debo notificar al api vtutor
        // billingToken: "BA-2VD05030HE492963P"
        // facilitatorAccessToken: "A21AAI61amDhuKdLOU4p_dXG7u4rmZEAFJFqr9MCY7un_m2fJuvh6n7vphobtjXmemLp2Fo0UsUHMdC9xqWmjG20CsY39rpUQ"
        // orderID: "7UK41804ED978241E"
        // paymentID: null
        // subscriptionID: "I-XJ3KMLFBDP0V"

        const { error, result } = await captureSubscriptionOrder(data.subscriptionID, email);

        if (error) {
          throw new Error(error.message);
        } else {
          if (result.status === "APPROVED" || result.status === "ACTIVE") {
            //update auth state and add the new courses
            addCourse(courses.split(","));

            //send data to GTM
            let order = {
              transaction_id: orderVtutor,
              total: total.total,
              currency: total.coin,
              coupon: coupon,
              items: gtm.items,
            };

            sendOrder(order);
            tapfiliateSendOrder(email, order);
            // ---------------

            setState((state) => ({
              ...state,
              success: true,
              orders: [orderVtutor],
              email: email,
              paymentMethod: {
                method: "paypal",
                payment_id: null,
              },
            }));
          } else {
            throw new Error(<Trans>Something happened with the subscription payment, please contact support.</Trans>);
          }
        }
      } else {
        const { error, result } = await captureOneTimeOrder(data.orderID, email);

        if (error) {
          throw new Error(error.message);
        } else {
          // Three cases to handle:
          //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          //   (2) Other non-recoverable errors -> Show a failure message
          //   (3) Successful transaction -> Show a success / thank you message

          // Your server defines the structure of 'orderData', which may differ
          var errorDetail = Array.isArray(result.details) && result.details[0];

          if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
            // Recoverable state, see: "Handle Funding Failures"
            // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            return actions.restart();
          }

          if (errorDetail) {
            // var msg = <Trans>Sorry, your transaction could not be processed.</Trans>;
            // if (errorDetail.description) msg += "\n\n" + errorDetail.description;
            // if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
            // Show a failure message
            throw new Error(<Trans>Something happened with the payment, please contact support.</Trans>);
          }

          if (result.status === "COMPLETED") {
            //update auth state and add the new courses
            addCourse(courses.split(","));

            //send data to GTM
            let order = {
              transaction_id: orderVtutor,
              total: total.total,
              currency: total.coin,
              coupon: coupon,
              items: gtm.items,
            };

            sendOrder(order);
            tapfiliateSendOrder(email, order);
            // ---------------

            //aca debo mandar al wizard
            setState((state) => ({
              ...state,
              success: true,
              orders: [orderVtutor],
              email: email,
              paymentMethod: {
                method: "paypal",
                payment_id: null,
              },
            }));
          }
        }
      }
    } catch (error) {
      setProcessing(false);

      Bugsnag.notify(error);
      setGlobalError(<Trans>Connection error, please refresh your page</Trans>);
    }
  };

  const handleCreateSubscription = async (data, actions) => {
    setProcessing(true);
    try {
      let body = {
        amount: Math.round(total.total * 100),
        course_id: courses,
        coursesData: coursesData,
        coupon,
        email: logged ? auth.email : values.emailPayPal,
        name: logged ? auth.full_name : " ",
        ga: ga,
        lang: lang,
        promo: promo,
        type: isClient ? sessionStorage.getItem("vtPriceType") : null,
      };

      const { error, result } = await createSubscription(body);

      if (error) {
        throw new Error(error.message);
      } else {
        return actions.subscription.create({
          plan_id: result.plan_id,
          application_context: {
            shipping_preference: "NO_SHIPPING",
          },
        });
      }
    } catch (e) {
      Bugsnag.notify(e);
      setProcessing(false);
      setGlobalError(<Trans>Connection error, please refresh your page</Trans>);
    }
  };

  const isSubscription = Boolean(total) && total.total !== null && total.productType !== "one_time_payment";

  const paypalOptions = {
    clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
    disableFunding: "card",
    components: "buttons",
    intent: isSubscription ? "subscription" : "capture",
    vault: isSubscription,
    currency: total.coin,
  };

  const onPaypalError = (e) => {
    setProcessing(false);
    Bugsnag.notify(e);
    setGlobalError(<Trans>An error has occurred, please try again or contact support</Trans>);
  };

  return (
    <div>
      <form className="Form space-y-4">
        <div className="mt-8 font-medium text-center">
          <Trans>You will be transferred to PayPal's secure servers.</Trans>
        </div>
        <div>
          {!logged && (
            <>
              <label htmlFor="email" className="absolute -mt-3 ml-4 bg-white px-1">
                <Trans>Email</Trans>
              </label>
              <Input
                type="email"
                name="emailPayPal"
                id="emailPayPal"
                placeholder="Email to send the access to the course"
                className="p-3"
                onChange={handleChange}
                disabled={loading()}
                dynamicPlaceholder={false}
              />
              <InputValidationMessage field="emailPayPal" errors={errors} />
            </>
          )}
        </div>
        {globalError && (
          <div className="bg-red-200 border-red-300 text-red-600 rounded-brand px-4 py-2 mt-4 text-center font-medium">{globalError}</div>
        )}

        <div>
          {loading() && (
            <div className="rounded w-full h-12 flex items-center bg-gray-200">
              <LoaderPaypal />
            </div>
          )}

          <ButtonStateful type="submit" loading={true} className={processing ? "block" : "hidden"} style={{ height: 55 }}>
            <span>Please wait...</span>
          </ButtonStateful>
          <div className={processing ? "hidden" : "block"}>
            <PayPalButton
              onInit={handleInit}
              onClick={handleValidation}
              options={paypalOptions}
              {...(isSubscription ? { createSubscription: handleCreateSubscription } : { createOrder: handleCreateOrder })}
              onApprove={handleOnApprove}
              onButtonReady={() => setVisibility(true)}
              onCancel={() => setProcessing(false)}
              onError={onPaypalError}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const CheckoutPaypalForm = ({ className, ...rest }) => {
  return (
    <div className={className}>
      <Form {...rest} />
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCourse: addCourse,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CheckoutPaypalForm);
