import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import PricesContext from "../../providers/pricesProvider";
import Period from "../transHelpers/period";
import Loader from "../loader";

const ProductSmall = ({ ID, image, title, funcCart, link, showRemove = true, i18n }) => {
  const prices = JSON.stringify(useContext(PricesContext));
  const [price, set_price] = useState("");
  const [price_old, set_price_old] = useState("");
  const [price_type, set_price_type] = useState("");
  const [details, set_details] = useState("");
  const [symbol, set_symbol] = useState("");

  useEffect(() => {
    const dec_prices = JSON.parse(prices);

    if (dec_prices !== null && dec_prices[ID] !== undefined) {
      set_price(dec_prices[ID].price);
      set_price_old(dec_prices[ID].price_old);
      set_price_type(dec_prices[ID].type);
      set_details(dec_prices[ID].sub_details);
      set_symbol(dec_prices.currency.symbol);
    }

    if (dec_prices === "error") {
      set_price(dec_prices);
    }

    // console.log(prices, ID);

    return () => {
      return false;
    };
  }, [prices, ID]);

  const isDescounted = () => {
    return price_old !== "" && price !== price_old;
  };

  return (
    <div className="flex justify-center items-center bg-white shadow rounded-brand w-full">
      <div className="w-26">
        <img
          src={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_225,h_150,c_fill,g_auto,q_auto:best/${image}`}
          title={title}
          alt={title}
          style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
          className="w-26 h-20 md:w-40"
        />
      </div>
      <div className="px-2 md:px-4 flex-1">
        <div className="flex flex-col pt-1 md:pt-2 h-full">
          <div className="leading-tight mb-2 text-xs">{title}</div>
          <div className="text-right mt-auto pb-1 md:pb-2 leading-tight text-sm">
            {price === "" ? (
              <div className="flex justify-end">
                <Loader reverse={true} className="m-0" />
              </div>
            ) : (
              <>
                {Number(price) > 0 ? (
                  <>
                    {isDescounted() && (
                      <span className="text-xs mb-1 mt-1 mr-2">
                        <span className="line-through text-gray-600">
                          {price_old} {symbol}
                        </span>
                      </span>
                    )}
                    <span className="font-bold text-brand-primary whitespace-nowrap">
                      {details !== "" && (
                        <>
                          {details.period_amount === "-1" ? (
                            <div className="line-through text-gray-700 font-light">
                              {price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                            </div>
                          ) : (
                            <div className="lowercase">
                              {details.period_amount} <Period amount={details.period_amount} period={details.period} />{" "}
                              {details.period_price === "free" ? <Trans>Free</Trans> : details.period_price}{" "}
                              {details.period_price !== "free" && symbol}
                            </div>
                          )}
                        </>
                      )}
                      {details !== "" && details.period_amount === "-1" ? (
                        <>
                          {details.period_price === "free" ? (
                            <>
                              <Trans>Free</Trans>
                            </>
                          ) : (
                            <>
                              {details.period_price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {details !== "" && (
                            <>
                              <Trans>then</Trans>{" "}
                            </>
                          )}
                          {price} {symbol}
                          {price_type !== "one_time_payment" && (
                            <>
                              {price_type === "yearly_subscription" && (
                                <span>
                                  {" "}
                                  / <Trans>year</Trans>
                                </span>
                              )}
                              {price_type === "monthly_subscription" && (
                                <span>
                                  {" "}
                                  / <Trans>month</Trans>
                                </span>
                              )}
                              {price_type === "weekly_subscription" && (
                                <span>
                                  {" "}
                                  / <Trans>week</Trans>
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </span>
                  </>
                ) : price === "error" ? (
                  <span
                    className="icon icon-info-circle text-red-600"
                    title={i18n._(t`Error loading prices, please refresh the page to try again.`)}
                  />
                ) : (
                  <div className="font-bold text-brand-primary leading-none text-sm md:text-base">
                    <Trans>Free</Trans>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showRemove && (
        <div className="py-4 text-xl flex items-center">
          <div className="w-8 md:w-12 border-l border-gray-300 h-full flex items-center justify-center">
            <button onClick={funcCart({ ID })} className="text-gray-400 h-full w-full">
              <i className="icon icon-times" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

ProductSmall.propTypes = {
  ID: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  funcCart: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};

export default withI18n()(ProductSmall);
