export async function getCartFromIds(ids) {
  try {
    const pattern = /^(([0-9]+)(,(?=[0-9]))?)+$/;

    if (!pattern.test(ids)) {
      return { error: "invalid list ids" };
    }

    let error = null;

    try {
      const response = await fetch(`${process.env.GATSBY_API_LP_REVIEWS}/courses/?course_ids=${ids}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const { status, msg, products } = await response.json();

      if (status && status === "error") {
        error = msg;
      } else {
        return { products };
      }
    } catch (e) {
      error = e;
    }

    return { error };
  } catch (error) {
    console.log(error);
    return { error };
  }
}
