import React, { useContext, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { useStripe } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addCourse } from "~/redux/actions/auth_actions";
import CoursesList from "../coursesList";
import { Trans, Plural } from "@lingui/macro";
import { CheckoutContext } from "~/providers/checkoutProvider";
import { capturePayment } from "~/services/stripe/capturePayment";
import LoadingOrder from "~/components/checkout/loadingOrder";
import PayPalButton from "~/components/checkout/paypal/checkout-paypal-button";
import { createOrder } from "~/services/paypal/createOrder";
import { captureOneTimeOrder } from "~/services/paypal/captureOneTimeOrder";
import { sendOrder } from "~/services/gtm/sendOrder";
import ButtonStateful from "~/components/buttonStateful";
import Countdown from "~/components/countdown";

const Upsell = ({ changeSection, data, addCourse }) => {
  const [
    {
      total,
      paymentMethod,
      email,
      name,
      coupon,
      ga,
      lang,
      promo,
      orders,
      courses,
      coursesData,
      gtm
    },
    setState
  ] = useContext(CheckoutContext);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  let orderVtutor;

  const stripe = useStripe();

  //  --- ACA es para los precios ---
  let ids = [];
  if (data.upsell.length > 0) ids = data.upsell.map((course) => +course.ID);
  // --------------------------------

  const offers = data.upsell;

  const totalUpsell = data.upsell.reduce((total, value) => {
    return (total = total + +value.price);
  }, 0);

  const goToSection = data.guest ? "password" : "finish";

  const stripePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // The card is valid, send the data to the backend to charge with stripe
      let data = {
        payment_method_id: paymentMethod.payment_id,
        amount: "upsell",
        courses: courses, // we must send the parent courses with the amount "upsell" to get childrens prices
        coupon,
        name: name,
        email: email,
        ga: ga,
        lang: lang,
        promo: promo
      };

      const { error, client_secret, payment_details } = await capturePayment(
        data
      );

      await handleServerResponseStripe(
        error,
        client_secret,
        payment_details,
        data
      );
    } catch (e) {
      Bugsnag.notify(e);
      setLoading(false);
      console.log(e);
    }
  };

  const handleServerResponseStripe = async (
    error,
    client_secret,
    payment_details,
    data
  ) => {
    if (error) {
      if (+error.code === 20) {
        // Use Stripe.js to handle the required card action
        const { error: errorAction, paymentIntent } =
          await stripe.handleCardAction(client_secret);

        if (errorAction) {
          // Show error from Stripe.js in payment form
          setLoading(false);
          // setGlobalError(<Trans>Your card was declined, authentication failed.</Trans>);
          alert(<Trans>Your card was declined, authentication failed.</Trans>);
          return false;
        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          data.payment_intent_id = paymentIntent.id;

          const { error, payment_details } = await capturePayment(data);

          await handleServerResponseStripe(
            error,
            client_secret,
            payment_details,
            data
          );
        }
      } else {
        throw new Error(error.message);
      }
    } else {
      // payment success!!!!
      // set the data in the context for the upsell
      // paymentMethod, email, stripe_customer_id, stripe_payment_id, order ref
      // set with handleSucces method to true
      //update auth state and add the new courses (data.upsell)
      addCourse(ids);

      //send data to GTM
      let order = {
        transaction_id: payment_details.orders.join(","),
        // total: total.total,
        total: totalUpsell,
        currency: total.coin,
        coupon: coupon,
        items: gtm.items
      };

      sendOrder(order);
      // ---------------

      let allOrders = orders;
      allOrders.push(payment_details.orders);

      setState((state) => ({
        ...state,
        orders: allOrders
      }));

      setPaymentSuccess(true);
      setTimeout(() => changeSection(goToSection), 2000);
    }
  };

  // paypal methods
  const paypalOptions = {
    clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
    disableFunding: "card",
    components: "buttons",
    intent: "capture",
    vault: false,
    currency: total.coin
  };

  const handleCreateOrder = async () => {
    setProcessing(true);

    let body = {
      amount: "upsell",
      courses: courses,
      coursesData,
      coupon,
      name: name,
      email: email,
      ga: ga,
      lang: lang,
      promo: promo
    };

    try {
      const { error, result } = await createOrder(body);
      if (error) {
        throw new Error(error.message);
      } else {
        // for send to GTM
        orderVtutor = result.orderVtutor;

        let allOrders = orders;
        allOrders.push(result.orderVtutor);

        setState((state) => ({
          ...state,
          orders: allOrders
        }));

        return result.orderID;
      }
    } catch (error) {
      Bugsnag.notify(error);
      // setGlobalError(error.message);
      setProcessing(false);
    }
  };

  const handleOnApprove = async (data, actions) => {
    try {
      const { error, result } = await captureOneTimeOrder(data.orderID, email);

      if (error) {
        throw new Error(error.message);
      } else {
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show a success / thank you message

        // Your server defines the structure of 'orderData', which may differ
        var errorDetail = Array.isArray(result.details) && result.details[0];

        if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
          // Recoverable state, see: "Handle Funding Failures"
          // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
          return actions.restart();
        }

        if (errorDetail) {
          // var msg = <Trans>Sorry, your transaction could not be processed.</Trans>;
          // if (errorDetail.description) msg += "\n\n" + errorDetail.description;
          // if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
          // Show a failure message
          throw new Error(
            (
              <Trans>
                Something happened with the payment, please contact support.
              </Trans>
            )
          );
        }

        if (result.status === "COMPLETED") {
          //update auth state and add the new courses (data.upsell)
          addCourse(ids);

          //send data to GTM
          let order = {
            transaction_id: orderVtutor,
            // total: total.total,
            total: totalUpsell,
            currency: total.coin,
            coupon: coupon,
            items: gtm.items
          };

          sendOrder(order);
          // ---------------

          //aca debo mandar al wizard
          setPaymentSuccess(true);
          // setTimeout(() => changeSection(goToSection), 2000);
          changeSection(goToSection);
        }
      }
    } catch (e) {
      Bugsnag.notify(e);
      // setGlobalError(e.message);
    }
  };

  return (
    <>
      {loading && <LoadingOrder success={paymentSuccess} />}
      <div className="text-center mt-4">
        <h1 className="text-3xl md:text-4xl">
          <Trans>Unlocked offer</Trans> &#127881;
        </h1>
        <h3 className="mt-1 text-gray-500 text-lg">
          <Plural
            value={offers.length}
            zero="There're no offers"
            one="Add this course to your purchase. Limited time offer"
            other="Add these courses to your purchase. Limited time offer"
          />
        </h3>
      </div>
      <div className="my-4 flex items-center justify-center text-lg text-gray-500">
        <span className="mr-2">
          <Trans>Ends in</Trans>
        </span>
        <Countdown onTimeOut={() => changeSection(goToSection)} />
        <span className="text-4xl ml-2" style={{ marginTop: 7 }}>
          &#127873;
        </span>
      </div>
      <div className="mt-4">
        <CoursesList cart={{ products: offers }} upsell={true} />
      </div>
      <div className="text-center md:text-right mt-4">
        <div className="block mb-2 font-semibold text-lg">
          Total: {totalUpsell} {total.symbol}
        </div>
        <div className="flex flex-col space-y-2 md:flex-row-reverse md:space-y-0 ">
          {paymentMethod.method === "stripe" ? (
            <button
              className="btn btn-primary whitespace-nowrap uppercase w-full md:w-64"
              onClick={stripePayment}
            >
              <Plural
                value={offers.length}
                zero="There're no offers"
                one="Buy this course"
                other="Buy these courses"
              />
            </button>
          ) : (
            <div className="w-full md:w-64">
              <ButtonStateful
                type="submit"
                loading={true}
                className={processing ? "block" : "hidden"}
                style={{ height: 42 }}
              >
                <span>Please wait...</span>
              </ButtonStateful>
              <div className={processing ? "hidden" : "block"}>
                <PayPalButton
                  options={paypalOptions}
                  createOrder={handleCreateOrder}
                  onApprove={handleOnApprove}
                  onButtonReady={() => console.log("ready")}
                  style={{ height: 42 }}
                  onCancel={() => setProcessing(false)}
                  onError={(e) => {
                    setProcessing(false);
                    Bugsnag.notify(e);
                  }}
                />
              </div>
            </div>
          )}

          <button
            className="whitespace-nowrap w-full underline text-gray-500 hover:text-gray-700  md:w-48 md:mr-4 text-lg"
            style={{ height: 42 }}
            onClick={() => {
              changeSection(goToSection);
            }}
            disabled={processing}
          >
            <Trans>Skip</Trans>
          </button>
        </div>
      </div>
    </>
  );
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCourse: addCourse
    },
    dispatch
  );
};

export default connect(null, mapActionsToProps)(Upsell);
