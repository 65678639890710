import React, { useContext } from "react";
import PropTypes from "prop-types";
import { CheckoutContext } from "~/providers/checkoutProvider";

const PaymentSelector = ({ handlePaymentMethod, paymentMethod }) => {
  const [state] = useContext(CheckoutContext);
  const { country, mixed, multiSubs } = state;

  const selectedPaymentBorder = "border-gray-300 border rounded-brand";

  return (
    <>
      <div
        className={`flex-1 md:flex-none flex items-center space-x-2 ${
          paymentMethod === "credit-card" && selectedPaymentBorder
        }`}
      >
        <label
          htmlFor="credit-card"
          className="flex items-center space-x-2 cursor-pointer radio py-3 pr-3"
        >
          <input
            type="radio"
            value="credit-card"
            name="paymentMethod"
            id="credit-card"
            onChange={handlePaymentMethod}
            checked={paymentMethod === "credit-card"}
            disabled={country === null}
          />
          <i className="custom-radio" />
          <img
            src="https://res.cloudinary.com/ie1/image/upload/f_auto,w_150,q_auto/v1594643502/vtutor/credit-cards.png"
            alt="Payment"
            className="inline-block"
            style={{ width: 100 }}
          />
        </label>
      </div>
      {!mixed && !multiSubs && (
        <div
          className={`flex-1 md:flex-none flex items-center space-x-2 ${
            paymentMethod === "paypal" && selectedPaymentBorder
          }`}
        >
          <label
            htmlFor="paypalOption"
            className="flex items-center space-x-2 cursor-pointer radio py-3 pr-3"
          >
            <input
              type="radio"
              value="paypal"
              name="paymentMethod"
              id="paypalOption"
              onChange={handlePaymentMethod}
              disabled={country === null}
            />
            <i className="custom-radio" />
            <img
              src="https://res.cloudinary.com/ie1/image/upload/f_auto,w_120,q_auto/v1594643502/vtutor/paypal.png"
              alt="Payment"
              className="inline-block ml-4"
              style={{ width: 80 }}
            />
          </label>
        </div>
      )}
    </>
  );
};

PaymentSelector.propTypes = {
  handlePaymentMethod: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
};

export default PaymentSelector;
