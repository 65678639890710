export async function attachPayment(payment_method_id, email, name) {
  let error = null;
  let result = "fail";

  try {
    if (payment_method_id) {
      // Setup payment, this will give us the client_secret, we need to fetch it on Buy click event because
      // we need to check with the email if the user have an stripe account created as custumer
      const response = await fetch(`${process.env.GATSBY_API_CHECKOUT}/pay/attach`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          name: name,
          payment_method_id: payment_method_id,
        }),
      });

      const { error } = await response.json();

      if (!response.ok) {
        throw new Error(error ? error : response.statusText);
      }

      if (error) {
        throw new Error(error);
      } else {
        result = "success";
      }
    }
  } catch (e) {
    error = e;
  }

  return { error, result };
}
