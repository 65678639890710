export async function getPaymentMethods() {
  let error = null;
  let paymentMethods = null;

  try {
    const response = await fetch(
      `${process.env.GATSBY_API_CHECKOUT}/pay/methods/`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const { error, payment_methods } = await response.json();

    if (error) {
      throw new Error(error);
    } else {
      paymentMethods = payment_methods;
    }
  } catch (e) {
    error = e;
  }

  return { error, payment_methods: paymentMethods };
}
