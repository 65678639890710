import React, { useState, useEffect } from "react";

const Countdown = (props) => {
  const [minutes, setMinutes] = useState(props.minutes);
  const [seconds, setSeconds] = useState(0);
  const { onTimeOut } = props;

  useEffect(() => {
    let timer = null;

    if (seconds === 0 && minutes === 0) {
      onTimeOut();
      return false;
    } else {
      timer = setTimeout(() => {
        if (seconds === 0) {
          setSeconds(59);
          if (minutes > 0) setMinutes(minutes - 1);
        } else setSeconds(seconds - 1);
      }, 1000);
    }

    // Clear timeout if the component is unmounted
    return () => (timer !== null ? clearTimeout(timer) : false);
  }, [seconds, minutes, onTimeOut]);

  return (
    <div className="flex items-center space-x-2 text-white font-semibold text-2xl">
      <span className="py-2 px-3 bg-brand-primary rounded-brand">{formatTime(minutes)}</span>
      <span className="text-gray-800">:</span>
      <span className="py-2 px-3 bg-brand-primary rounded-brand">{formatTime(seconds)}</span>
    </div>
  );
};

const formatTime = (time) => {
  if (time < 10) return `0${time}`;

  return time;
};

Countdown.defaultProps = {
  minutes: 5,
  onTimeOut: () => {
    return false;
  },
};

export default Countdown;
