import React, { useEffect, useState } from "react";
import Layout from "~/components/layout";
import { isClient } from "~/utils/app-helpers";
import Payment from "~/components/checkout/payment";
import { CheckoutProvider } from "~/providers/checkoutProvider";
import { getParameterByName } from "~/utils/app-helpers";
import LoadingCheckout from "~components/checkout/loadingCheckout";
import CartNotFound from "~components/checkout/cartNotFound";
import { getCartFromIds } from "~/services/checkout/getCartFromIds";

const CheckoutPage = (props) => {
  const paths = props.pageContext.paths;
  const [cart, setCart] = useState(() => (isClient ? JSON.parse(sessionStorage.getItem("vtCart")) : null));
  const [cartNotFound, setCartNotFound] = useState(false);

  const fetchCartFromAPI = async (ids) => {
    const { error, products } = await getCartFromIds(ids);

    if (error || products.length === 0) {
      setCartNotFound(true);
      return false;
    }

    setCart({ products });
  };

  useEffect(() => {
    if (isClient) {
      if (cart === null || cart.length === 0 || cart.products.length === 0) {
        const course_id = getParameterByName("cs");

        if (course_id === null || course_id === "") {
          setCartNotFound(true);
          return false;
        }

        fetchCartFromAPI(course_id);
      }
    }

    return () => {
      sessionStorage.removeItem("vtCart");
      return false;
    };
  }, [cart]);

  if (!isClient) return false;

  return (
    <Layout paths={paths} hideWidgets={true} hideFooter={true} showSecure={true} hidePromoBar={true}>
      {cart === null && cartNotFound === false ? (
        <LoadingCheckout />
      ) : (
        <div className="p-2 md:p-4 md:py-6 ">
          <div className="md:mr-auto md:ml-auto lg:max-w-6xl">
            <div className="w-full">
              {cartNotFound ? (
                <CartNotFound />
              ) : (
                <CheckoutProvider>
                  <Payment cart={cart} />
                </CheckoutProvider>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CheckoutPage;
