import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { CheckoutContext } from "~/providers/checkoutProvider";
import Sanitize from "~/components/sanitize";

const ProductUpsell = ({ ID, image, title, subtitle, price, price_old }) => {
  const [{ total }] = useContext(CheckoutContext);

  useEffect(() => {
    return () => {
      return false;
    };
  });

  const isDescounted = () => {
    return price_old !== "" && price !== price_old;
  };

  return (
    <div className="flex justify-center items-stretch bg-white shadow rounded-brand w-full">
      <div>
        <img
          src={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_225,h_150,c_fill,g_auto,q_auto:best/${image}`}
          title={title}
          alt={title}
          style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
          className="w-32 h-full md:w-48 md:h-32"
        />
      </div>
      <div className="px-2 md:px-4 flex-1">
        <div className="flex flex-col pt-1 md:pt-2 h-full">
          <div className="leading-none mb-2 text-xs md:text-base">{title}</div>
          <Sanitize
            className="leading-none mb-2 text-xs hidden md:block md:text-sm text-gray-600"
            html={subtitle}
          />
          <div className="text-right mt-auto pb-1 md:pb-2 leading-tight text-xs md:text-base">
            {Number(price) > 0 ? (
              <>
                {isDescounted() && (
                  <div className="text-xs mb-1 mt-1">
                    <span className="bg-green-200 border border-green-200 p-1 px-2 mr-2 text-green-700 rounded-brand ">
                      {Math.floor(
                        100 - (Number(price) * 100) / Number(price_old)
                      )}
                      % OFF
                    </span>
                    <span className="line-through md:text-sm text-gray-600">
                      {price_old} {total.symbol}
                    </span>
                  </div>
                )}
                <span className="">
                  {price} {total.symbol}
                </span>
              </>
            ) : (
              <div className="font-bold text-brand-primary leading-none text-sm md:text-base">
                <Trans>Free</Trans>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProductUpsell.propTypes = {
  ID: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  price_old: PropTypes.string.isRequired,
};

export default ProductUpsell;
