export async function capturePayment(body) {
  let error = null;
  let paymentDetails = null;
  let clientSecret = null;

  try {
    const response = await fetch(`${process.env.GATSBY_API_CHECKOUT}/pay`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(JSON.stringify(await response.json()));
    }

    const { error: errorAction, code, client_secret, payment_details, orders } = await response.json();

    if (errorAction) {
      error = { message: errorAction, code: code, orders: orders };
      clientSecret = client_secret;
    } else {
      paymentDetails = payment_details;
    }
  } catch (e) {
    error = e;
  }

  return { error, client_secret: clientSecret, payment_details: paymentDetails };
}
