import React from "react";
import { Trans } from "@lingui/macro";
import Link from "~/components/link";

const CartNotFound = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="mt-52 text-2xl md:text-3xl md:mt-64 text-center">
        <Trans>No courses found</Trans>
        <Link
          to="/"
          className="no-underline btn btn-primary py-2 xl:py-3 block w-full mt-4"
        >
          <span className="mr-2 align-middle lg:tracking-wide">
            <Trans>Back to home</Trans>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default CartNotFound;
