import { useState, useRef, useContext, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { getUpsells } from "~/services/wizard/getUpsells";
import { CheckoutContext } from "~/providers/checkoutProvider";

export default function useWizardUpsells() {
  const [data, setData] = useState({});
  const [{ email, courses }] = useContext(CheckoutContext);
  const [isLoading, setLoadingStatus] = useState(true);
  const [error, setError] = useState("");
  const isMounted = useRef(true);

  async function getData() {
    try {
      const { success, message, upsell, guest, token } = await getUpsells(
        email,
        courses
      );

      if (isMounted.current) {
        if (success) {
          setData({
            success,
            message,
            upsell,
            guest,
            token,
          });
        } else {
          setError(message);
        }

        setLoadingStatus(false);
      }
    } catch (e) {
      Bugsnag.notify(e);
      if (isMounted.current) setError(e.message);
    }
  }

  useEffect(() => {
    getData();

    return () => {
      isMounted.current = false;
      return false;
    };

  }, []);

  return { data, isLoading, error };
}
