import React, { useEffect, useState } from "react";

const findErrorIndex = (errors, elementType) => {
  return typeof errors[elementType] !== "undefined";
};

const ValidationMessage = ({ message }) => {
  return (
    <div
      className={`text-red-700 ml-2 text-sm pt-1 ${message === "" && "hidden"}`}
    >
      {message}
    </div>
  );
};

const InputValidationMessage = ({ field, errors }) => {
  let encErrors = errors !== null ? JSON.stringify(errors) : "";
  const [message, setMessage] = useState(errors !== null && errors[field]);

  useEffect(() => {
    if (encErrors !== "") {
      let decErrors = JSON.parse(encErrors);

      let i = findErrorIndex(decErrors, field);

      if (i) setMessage(decErrors[field]);
      else setMessage("");
    } else {
      setMessage("");
    }

    return () => {
      return false;
    };
  }, [encErrors, field]);

  return <ValidationMessage message={message} />;
};

export default InputValidationMessage;
