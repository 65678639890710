import React, { useState, useEffect, useContext, useRef } from "react";
import Bugsnag from "@bugsnag/js";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReCAPTCHA from "react-google-recaptcha";
import { addCourse } from "~/redux/actions/auth_actions";
import { useForm } from "~/hooks/useForm";
import Input from "~/components/input";
import InputValidationMessage from "~/components/inputValidationMessage";
import cvc_amex from "~/images/cvc_amex.svg";
import cvc_others from "~/images/cvc_others.svg";
import { CheckoutContext } from "~/providers/checkoutProvider";
import { attachPayment } from "~/services/stripe/attachPayment";
import { capturePayment } from "~/services/stripe/capturePayment";
import { sendOrder } from "~/services/gtm/sendOrder";
import { sendOrder as tapfiliateSendOrder } from "~/services/tapfiliate/sendOrder";
import { captcha } from "~/services/auth/captcha";
import { snakeCase, isClient } from "~/utils/app-helpers";
import LoadingOrder from "../loadingOrder";

const Form = ({ setLoading, auth, stripePaymentMethods, addCourse, i18n }) => {
  const [state, setState] = useContext(CheckoutContext);
  const { total, courses, coursesData, coupon, country, ga, lang, promo, gtm, utm_src } = state;

  const [errors, setErrors] = useState({});
  const [elementsReady, setElementstatus] = useState(false);
  const [cardBrand, setCardBrand] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [globalError, setGlobalError] = useState("");
  const [submitCounter, setSubmitCounter] = useState(0);

  const stripe = useStripe();
  const elements = useElements();
  const [values, handleChange] = useForm({
    name: "",
    email: "",
    postal_code: "",
    card_number: "",
    card_expiry: "",
    card_cvc: "",
  });

  const inputStyle = {
    style: {
      base: {
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  useEffect(() => {
    if (auth.email && stripePaymentMethods.length > 0) {
      setSelectedMethod(stripePaymentMethods[0].id);
    }

    return () => {
      return false;
    };
  }, [auth.email, stripePaymentMethods]);

  const handleStripeElementChange = (event) => {
    let { ...myErrors } = errors;
    let elementName = snakeCase(event.elementType);

    if (event.brand) {
      setCardBrand(event.brand);
    }

    if (event.empty) {
      handleChange({ [elementName]: "" });
    } else {
      handleChange({ [elementName]: "full" });
    }

    if (event.error !== undefined) {
      myErrors[elementName] = event.error.message;
      setErrors(myErrors);
    } else {
      delete myErrors[elementName];
      setErrors(myErrors);
    }
  };

  const validateFields = () => {
    let myErrors = {};

    Object.keys(values).forEach((i) => {
      if (values[i] === "") {
        switch (i) {
          case "postal_code":
            if (country === "US") myErrors[i] = "Required field";
            break;
          case "email":
            if (auth.email === null) myErrors[i] = "Required field";
            break;
          default:
            myErrors[i] = "Required field";
        }
      } else {
        //myErrors[i] = "";
      }
    });

    if (Object.keys(myErrors).length > 0) {
      setErrors(myErrors);

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubmitCounter(submitCounter + 1);

    if (!stripe || !elements) {
      return false;
    }

    if (selectedMethod === null) if (validateFields() === false) return false;

    try {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      setLoading(true);
      setGlobalError("");
      setErrors({});

      let email = auth.email !== null ? auth.email : values.email;
      let paymentMethodId = selectedMethod;

      const { error: captchaError } = await captcha(captchaToken);

      if (captchaError) {
        Bugsnag.notify(new Error(captchaError.message));
        setGlobalError(captchaError.message);
        setLoading(false);
        return false;
      }

      // new flow
      if (selectedMethod === null) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: {
            ...elements.getElement(CardNumberElement),
          },
          billing_details: {
            email: email,
            name: values.name,
            address: {
              postal_code: values.postal_code,
            },
          },
        });

        if (error) {
          Bugsnag.notify(new Error(error.message));
          setGlobalError(error.message);
          setLoading(false);
          return false;
        }

        //attach and set default payment for the customer
        const { error: attachError } = await attachPayment(paymentMethod.id, email, values.name);

        if (attachError) {
          Bugsnag.notify(new Error(attachError.message));
          setGlobalError(attachError.message);
          setLoading(false);
          return false;
        }

        paymentMethodId = paymentMethod.id;
      }

      // using existing payment method from the select
      // create the payment
      const data = {
        payment_method_id: paymentMethodId,
        amount: Math.round(total.total * 100),
        courses,
        coursesData,
        coupon,
        name: selectedMethod !== null ? auth.full_name : values.name,
        email: email,
        ga: ga,
        utm_src: utm_src,
        lang: lang,
        promo: promo,
        type: isClient ? sessionStorage.getItem("vtPriceType") : null,
      };

      const { error, client_secret, payment_details } = await capturePayment(data);

      await handleServerResponse(error, client_secret, payment_details, data);
      //end new flow
    } catch (error) {
      Bugsnag.notify(error);
      setGlobalError(error.message);
      setLoading(false);
    }
  };

  const handleServerResponse = async (error, client_secret, payment_details, data) => {
    if (error) {
      // if require action
      if (+error.code === 20) {
        // Use Stripe.js to handle the required card action
        const { error: errorAction, paymentIntent } = await stripe.handleCardAction(client_secret);

        if (errorAction) {
          // Show error from Stripe.js in payment form
          setLoading(false);
          setGlobalError(<Trans>Your card was declined, authentication failed.</Trans>);
          return false;
        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          data.payment_intent_id = paymentIntent.id;

          const { error, payment_details } = await capturePayment(data);

          await handleServerResponse(error, client_secret, payment_details, data);
        }
      } else if (+error.code === 21) {
        const { error: errorSubAction } = await stripe.confirmCardPayment(client_secret);

        if (errorSubAction) {
          setLoading(false);
          setGlobalError(<Trans>Your card was declined, authentication failed.</Trans>);
          return false;
        } else {
          await handleServerResponse(null, client_secret, { orders: error.orders }, data);
        }
      } else {
        paymentErrorHandler(error);
      }
    } else {
      // Show success message
      paymentSuccessHandler(payment_details, data.payment_method_id, data.email);
    }
  };

  const paymentErrorHandler = (error) => {
    // Show error from server on payment form
    const myError = JSON.parse(error.message);
    setLoading(false);

    if (myError.code) {
      switch (myError.code) {
        case 1:
          setGlobalError(<Trans>Invalid e-mail address</Trans>);
          return false;
        case 10:
          setGlobalError(<Trans>Your card was declined, please contact your bank for details.</Trans>);
          return false;
        default:
          break;
      }
    }

    Bugsnag.notify(new Error(error.message));
    setGlobalError(<Trans>Something happened, please refresh the window and try again.</Trans>);
  };

  const paymentSuccessHandler = (payment_details, payment_method, email) => {
    if (payment_details) {
      //update auth state and add the new courses
      addCourse(courses.split(","));
      //send data to GTM
      const order = {
        transaction_id: payment_details.orders.join(","),
        total: total.total,
        currency: total.coin,
        coupon: coupon,
        items: gtm.items,
      };

      sendOrder(order);
      tapfiliateSendOrder(email, order);
      // ---------------

      setState((state) => ({
        ...state,
        success: true,
        orders: [payment_details.orders],
        paymentMethod: {
          method: "stripe",
          payment_id: payment_method,
        },
        name: selectedMethod !== null ? auth.full_name : values.name,
        email: email,
      }));
    }
  };

  const handleSelectedMethod = (method_id) => (e) => {
    e.preventDefault();

    setSelectedMethod(method_id);
    setErrors({});
    setGlobalError("");
  };

  const isLoading = () => {
    return !stripe || !elementsReady || !Boolean(total) || Object.keys(total).length === 0;
  };

  const recaptchaRef = useRef(null);

  return (
    <>
      {!isLoading() && typeof stripePaymentMethods !== "undefined" && stripePaymentMethods.length > 0 && (
        <div className="space-y-2 mb-8 mt-8">
          {stripePaymentMethods.map((val) => {
            return (
              <button className="leading-tight block hover:text-gray-900 ml-6" onClick={handleSelectedMethod(val.id)} key={`pm_${val.id}`}>
                <div className="flex items-center">
                  <img src={`/images/cards/${val.brand}.svg`} alt={val.brand} />
                  <span className="capitalize mx-2 text-sm">{val.brand}</span>
                  <span className="mr-4">•••• {val.last4}</span>
                  <div className="flex items-center text-gray-600 hover:text-gray-900 text-sm">
                    <span className="capitalize mr-2 text-sm">
                      <Trans>Expires</Trans>
                    </span>
                    <span>
                      {val.exp_month} / {val.exp_year}
                    </span>
                  </div>
                  {selectedMethod === val.id && <span className="icon icon-check-circle ml-4 text-xl text-green-600"></span>}
                </div>
              </button>
            );
          })}
          <div>
            <button className="flex items-center justify-center px-3 btn btn-secondary-2 mt-6 w-full" onClick={handleSelectedMethod(null)}>
              <img src={`/images/cards/card.svg`} alt="" className="mr-2" /> <Trans>Add a new card</Trans>
              {selectedMethod === null && <span className="icon icon-check-circle ml-4 text-xl text-green-600"></span>}
            </button>
          </div>
        </div>
      )}
      <form style={{ ...inputStyle.style.base }}>
        <fieldset disabled={isLoading() && stripePaymentMethods.length === 0}>
          <div className={`${selectedMethod === null ? "block" : "hidden"}`}>
            <div className="flex-1 mt-4 md:mt-5 relative pt-3">
              <label htmlFor="name" className="absolute -mt-3 ml-4 bg-white px-1" style={{ zIndex: 2 }}>
                <Trans>Card number</Trans>
              </label>
              <div className="border border-gray-300 focus-within:border-gray-500 rounded-brand p-3 md:p-4 h-12" style={{ paddingTop: 14 }}>
                <CardNumberElement
                  options={{
                    ...inputStyle,
                    showIcon: true,
                    iconStyle: "solid",
                  }}
                  onChange={handleStripeElementChange}
                  disabled={!stripe}
                />
              </div>
              <InputValidationMessage field="card_number" errors={errors} />
            </div>
            <div className="flex flex-col md:space-x-4 md:flex-row">
              <div className="flex-1 mt-4 md:mt-5 md:w-1/4 relative">
                <label htmlFor="name" className="absolute -mt-3 ml-4 bg-white px-1" style={{ zIndex: 2 }}>
                  <Trans>Expiration</Trans>
                </label>
                <div className="flex-1 border border-gray-300 rounded-brand px-8 p-3 md:p-4 relative h-12" style={{ paddingTop: 14 }}>
                  <CardExpiryElement
                    options={{
                      ...inputStyle,
                    }}
                    onChange={handleStripeElementChange}
                    disabled={!stripe}
                  />
                </div>
                <InputValidationMessage field="card_expiry" errors={errors} />
              </div>
              <div className="flex-1 mt-4 md:mt-5 md:w-1/4 relative">
                <label htmlFor="name" className="absolute -mt-3 ml-4 bg-white px-1" style={{ zIndex: 2 }}>
                  <Trans>CVC</Trans>
                </label>
                <div className="flex items-center space-x-2">
                  <div className="flex-1 border border-gray-300 rounded-brand p-3 md:p-4 relative h-12" style={{ paddingTop: 14 }}>
                    <CardCvcElement
                      options={{
                        ...inputStyle,
                      }}
                      onChange={handleStripeElementChange}
                      disabled={!stripe}
                      onReady={() => setElementstatus(true)}
                    />
                    <div className="absolute right-0 mr-3" style={{ marginTop: -23 }}>
                      {cardBrand === "amex" ? (
                        <img src={cvc_amex} alt="amex" className="cvc_icon" />
                      ) : (
                        <img src={cvc_others} alt="others" className="cvc_icon" />
                      )}
                    </div>
                  </div>
                </div>
                <InputValidationMessage field="card_cvc" errors={errors} />
              </div>
            </div>
            {country !== null && country.toUpperCase() === "US" && (
              <div className="mt-4 md:mt-5 md:w-1/4 relative">
                <label htmlFor="name" className="absolute -mt-3 ml-4 bg-white px-1">
                  <Trans>Postal code</Trans>
                </label>
                <Input
                  type="text"
                  name="postal_code"
                  id="postal_code"
                  onChange={handleChange}
                  placeholder={i18n._(t`Your Postal code`)}
                  className="h-12"
                  disabled={!stripe}
                  dynamicPlaceholder={false}
                  autoComplete="off"
                />
                <InputValidationMessage field="postal_code" errors={errors} />
              </div>
            )}
            <div className="mt-4 md:mt-5 relative">
              <label htmlFor="name" className="absolute -mt-3 ml-4 bg-white px-1">
                <Trans>Name on card</Trans>
              </label>
              <Input
                type="text"
                name="name"
                id="name"
                onChange={handleChange}
                placeholder={i18n._(t`Card holder name`)}
                className="h-12"
                disabled={!stripe}
                dynamicPlaceholder={false}
                autoComplete="off"
              />
              <InputValidationMessage field="name" errors={errors} />
            </div>
          </div>
          {auth.email === null && (
            <div className="mt-4 md:mt-5 relative">
              <label htmlFor="email" className="absolute -mt-3 ml-4 bg-white px-1">
                <Trans>Email</Trans>
              </label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                placeholder={i18n._(t`Email to send the access to the course`)}
                className="h-12"
                disabled={!stripe}
                dynamicPlaceholder={false}
                autoComplete="off"
              />
              <InputValidationMessage field="email" errors={errors} />
            </div>
          )}
          <div className="mt-3">
            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} size="invisible" />
          </div>

          {globalError && (
            <div className="bg-red-200 border-red-300 text-red-600 rounded-brand px-4 py-2 mt-4 text-center font-medium">{globalError}</div>
          )}

          <div className="mt-4 md:mt-6">
            <button type="submit" to="/checkout" className="btn btn-primary w-full block text-center" disabled={!stripe} onClick={handleSubmit}>
              <span className="text-xl">
                {Boolean(total) && typeof total.total !== "undefined" && total.total > 0 ? <Trans>Buy</Trans> : <Trans>Join now</Trans>}{" "}
                {Boolean(total) && typeof total.total !== "undefined" && `${total.total} ${total.symbol}`}
              </span>
            </button>
          </div>
        </fieldset>
      </form>
    </>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

export const CheckoutStripeForm = ({ className, stripe, ...rest }) => {
  const [show, setVisibility] = useState(false);
  const [{ success }] = useContext(CheckoutContext);

  return (
    <>
      {show && <LoadingOrder success={success} />}
      <div className={className}>
        <Elements stripe={stripe}>
          <Form {...rest} setLoading={setVisibility} />
        </Elements>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCourse: addCourse,
    },
    dispatch
  );
};

export default withI18n()(connect(mapStateToProps, mapActionsToProps)(CheckoutStripeForm));
