export async function getUpsells(email, courses) {
  let error = null;

  try {
    const response = await fetch(
      `${process.env.GATSBY_API_WIZARD}/status?email=${email}&courses=${courses}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const { success, message, upsell, guest, token } = await response.json();

    return { success, message, upsell, guest, token };
  } catch (e) {
    error = e;
  }

  return { error };
}
