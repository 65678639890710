import React from "react";
import { Trans } from "@lingui/macro";
import ModalWrapper from "~/components/modalWrapper";
import { Image } from "~/components/auth/submitSuccess";
import loader from "~/images/carts-anim-v1-min.svg";

const LoadingOrder = ({ success }) => {
  return (
    <ModalWrapper width="350px" height="350px" disableUserHide={true}>
      {success ? <Success /> : <Loading />}
    </ModalWrapper>
  );
};

LoadingOrder.defaultProps = {
  success: false,
};

const Success = () => {
  return (
    <div className="text-center pt-16">
      <div className="inline-block w-32">
        <Image />
      </div>
      <p className="mt-3">
        <Trans>Redirecting... please wait...</Trans>
      </p>
    </div>
  );
};

const Loading = () => {
  return (
    <div className="text-center mt-2 sm:pt-8">
      <h2>
        <Trans>Processing Order</Trans>
      </h2>
      <div className="text-gray-700 text-lg mt-3">
        <p>
          <Trans>
            Do <span className="text-gray-800 font-semibold">NOT CLOSE</span> this
            window.
          </Trans>
        </p>
      </div>
      <div className="text-center mb-4 mt-2">
        {/* <Loader reverse={true} className="text-6xl my-8" /> */}
        {/* <video
          src="https://res.cloudinary.com/ie1/video/upload/c_scale,f_auto,h_160,q_100,w_160/v1605518322/vtutor/cart-3_rfepb9.mp4"
          type="video/mp4"
          width="110"
          height="110"
          autoPlay
          loop
          className="inline-block"
        ></video> */}
        <div className="flex items-center justify-center h-full ">
          <img src={loader} alt="please wait..." className="w-32 h-32" />
        </div>
      </div>
      <p className="text-xl">
        <Trans>Please wait a little bit...</Trans>
      </p>
    </div>
  );
};

export default LoadingOrder;
